<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <!-- <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本校学生智慧卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
        >
        </el-switch>
      </div> -->
      <div class="details">
        基础信息<img
          src="../assets/ico_you.png"
          style="margin-top: 2px"
        />教师管理
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div
        class="item"
        style="
          background: #f5f6fa;
          color: #5d9cff;
          border-bottom: 1px solid #b9d5fa;
        "
        @click="$router.push('/basicManage')"
      >
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/ico_jichuguanli_blue.png"
          />基础管理
        </div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/schoolManage')"
      >
        <div style="display: flex; align-items: center">校区管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/gradeManage')"
      >
        <div style="display: flex; align-items: center">年级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/classManage')"
      >
        <div style="display: flex; align-items: center">班级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #5d9cff;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
      >
        <div style="display: flex; align-items: center">教师管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/studentManage')"
      >
        <div style="display: flex; align-items: center">学生管理</div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content">
      <div class="card">
        <div class="inner">
          <div
            style="
              font-size: 16px;
              margin-bottom: 35px;
              margin-top: 35px;
              position: relative;
            "
          >
            <span>年级：</span>
            <el-select
              v-model="inputGrade"
              placeholder="请选择"
              @change="selectGradeSearch"
              clearable
            >
              <el-option
                v-for="item in gradeList"
                :key="item.gradeName"
                :label="item.gradeName"
                :value="item.gradeId"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-model="inputGrade"
              placeholder="请输入"
              style="width: 230px"
            ></el-input> -->
            <span style="margin-left: 80px">班级：</span>
            <el-select
              v-model="inputClass"
              placeholder="请选择"
              @change="selectClassSearch"
              clearable
            >
              <el-option
                v-for="item in classList"
                :key="item.gradeName + item.className"
                :label="item.gradeName + item.className"
                :value="item.classId"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-model="inputClass"
              placeholder="请输入"
              style="width: 230px"
            ></el-input> -->
            <span style="margin-left: 80px">教师姓名：</span>
            <el-input
              v-model="inputManager"
              placeholder="请输入"
              style="width: 230px"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              style="position: absolute; right: 20px; width: 100px"
              @click="search"
              >搜索</el-button
            >
          </div>
          <div style="font-size: 16px; margin-bottom: 35px; position: relative">
            数据列表
            <!-- <el-button
              type="warning"
              style="position: absolute; right: 130px; width: 100px"
              >导入</el-button
            > -->
            <el-button
              type="success"
              style="position: absolute; right: 130px; width: 100px"
              @click="exportExcel('表单')"
              >导出</el-button
            >
            <el-button
              type="primary"
              style="position: absolute; right: 20px; width: 100px"
              @click="
                dialogFormVisible = true;
                dialogFormVisibleUpdate = false;
                clearForm();
              "
              >新增</el-button
            >
          </div>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
            max-height="420px"
            ref="table"
          >
            <el-table-column
              type="index"
              label="序号"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="teacherPhone"
              label="教师电话"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="teacherName"
              label="教师姓名"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="className"
              label="班级"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="classId"
              label="班级编号"
              width="80"
              align="center"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="gradeName"
              label="年级"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="gradeId"
              label="年级编号"
              width="80"
              align="center"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="studentNum"
              label="学生数"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  @click="$router.push('/studentManage')"
                  style="color: blue"
                  >{{
                    scope.row.studentNum == undefined || null
                      ? "0"
                      : scope.row.studentNum
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="method" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                  style="color: red"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            :title="dialogFormVisibleUpdate ? '编辑教师' : '新增教师'"
            :visible.sync="dialogFormVisible"
            :width="'30%'"
          >
            <el-form :model="form" :rules="rules" ref="form">
              <!-- <el-form-item
                label="校区编号"
                :label-width="formLabelWidth"
                prop="schoolId"
                v-if="!dialogFormVisibleUpdate"
              >
                <el-input v-model="form.schoolId" autocomplete="off"></el-input>
              </el-form-item> -->
              <el-form-item
                label="教师名称"
                :label-width="formLabelWidth"
                prop="name"
              >
                <el-input v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item
                label="教师电话"
                :label-width="formLabelWidth"
                prop="phone"
                v-if="!dialogFormVisibleUpdate"
              >
                <el-input v-model="form.phone" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button
                @click="
                  dialogFormVisible = false;
                  dialogFormVisibleUpdate == false;
                "
                >取 消</el-button
              >
              <el-button
                type="primary"
                @click="validateForm"
                v-if="dialogFormVisibleUpdate == false"
                >确 定</el-button
              >
              <el-button
                type="primary"
                @click="validateFormUpdate"
                v-if="dialogFormVisibleUpdate == true"
                >确 定</el-button
              >
            </div>
          </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
          >
            <span>提交信息有误</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button
                type="primary"
                @click="dialogVisible = false"
                v-if="dialogFormVisibleUpdate == false"
                >确 定</el-button
              >
              <el-button
                type="primary"
                @click="dialogVisible = false"
                v-if="dialogFormVisibleUpdate == true"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  name: "teacherManage",
  components: {},
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      mapType: "class",
      allow: false,
      input: "",
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      dialogVisible: false,
      formLabelWidth: "100px",
      dialogFormVisible: false,
      dialogFormVisibleUpdate: false,
      form: {
        name: "",
        phone: "",
      },
      rules: {
        schoolId: [
          { required: true, message: "请输入校区编号", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入教师姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入教师电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      options: [
        {
          value: "选项1",
          label: "一年级",
        },
        {
          value: "选项2",
          label: "二年级",
        },
        {
          value: "选项3",
          label: "三年级",
        },
        {
          value: "选项4",
          label: "四年级",
        },
        {
          value: "选项5",
          label: "五年级",
        },
      ],
      options1: [
        {
          value: "选项1",
          label: "一班",
        },
        {
          value: "选项2",
          label: "二班",
        },
        {
          value: "选项3",
          label: "三班",
        },
        {
          value: "选项4",
          label: "四班",
        },
        {
          value: "选项5",
          label: "五班",
        },
      ],
      value: "",
      inputClass: "",
      inputGrade: "",
      inputManager: "",
      gradeList: [],
      classList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `${this.$baseUrl}/classes/findClasses?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.classList = response.data.result.records;
      });
    this.$http
      .get(
        `${this.$baseUrl}/class/group/findGrades?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.gradeList = response.data.result.records;
      });
    this.$http
      .get(
        `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.tableData = response.data.result.records;
      });
  },
  methods: {
    clearForm() {
      this.form.name = "";
      this.form.phone = "";
    },
    selectClassSearch(event) {
      console.log(event);
      this.inputClass = event;
    },
    selectGradeSearch(event) {
      console.log(event);
      this.inputGrade = event;
    },
    search() {
      if (
        this.inputGrade.length < 1 &&
        this.inputManager.length < 1 &&
        this.inputClass.length < 1
      ) {
        this.searched = false;
        this.$http
          .get(
            `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
          )
          .then((response) => {
            console.log(response.data.result.records);
            this.tableData = response.data.result.records;
          });
        return;
      }
      this.$http
        .get(
          `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=100${
            this.inputGrade.length > 0 ? "&gradeId=" + this.inputGrade : ""
          }${
            this.inputClass.length > 0 ? "&classesId=" + this.inputClass : ""
          }${
            this.inputManager.length > 0
              ? "&teacherName=" + this.inputManager
              : ""
          }&schoolId=${this.schoolData.schoolId}`
        )
        .then((response) => {
          console.log(response.data.result.records);
          this.searched = true;
          this.tableData = response.data.result.records;
        });
    },
    deleteRow(index, data) {
      this.$http
        .delete(
          `${this.$baseUrl}/teacher/info/delete/${data[index].teacherPhone}`
        )
        .then(() => {
          this.$http
            .get(
              `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
            )
            .then((response) => {
              console.log(response.data.result.records);
              this.tableData = response.data.result.records;
            });
        });
    },
    editRow(index, data) {
      this.dialogFormVisible = true;
      this.dialogFormVisibleUpdate = true;
      this.form.phone = data[index].teacherPhone;
      this.form.name = data[index].teacherName;
      console.log(this.dialogFormVisible);
    },
    exportExcel(excelName = "表单") {
      //excelName --设置导出的excel名称
      //report-table --对应的要导出的el-table的ref名称
      try {
        const $e = this.$refs["table"].$el;
        // 如果表格加了fixed属性，则导出的文件会生产两份一样的数据，所以可在这里判断一下
        let $table = $e.querySelector(".el-table__fixed");
        if (!$table) {
          $table = $e;
        }
        // 为了返回单元格原始字符串，设置{ raw: true }
        const wb = XLSX.utils.table_to_book($table, { raw: true });
        const wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          `${excelName}.xlsx`
        );
      } catch (e) {
        if (typeof console !== "undefined") console.error(e);
      }
    },
    validateForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$http
            .post(`${this.$baseUrl}/teacher/info/add`, {
              schoolId: this.schoolData.schoolId,
              teacherPhone: this.form.phone,
              teacherName: this.form.name,
            })
            .then((response) => {
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              console.log(response);
              if (response.data.code != 200) {
                this.dialogVisible = true;
              }
              this.$http
                .get(
                  `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
                )
                .then((response) => {
                  console.log(response.data.result.records);
                  this.tableData = response.data.result.records;
                });
            })
            .catch(() => {
              this.dialogVisible = true;
            });
        } else {
          return false;
        }
      });
    },
    validateFormUpdate() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$http
            .put(`${this.$baseUrl}/teacher/info/update`, {
              schoolId: this.schoolData.schoolId,
              teacherPhone: this.form.phone,
              teacherName: this.form.name,
            })
            .then((response) => {
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              console.log(response);
              if (response.data.code != 200) {
                this.dialogVisible = true;
              }
              this.$http
                .get(
                  `${this.$baseUrl}/teacher/info/findTeachers?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
                )
                .then((response) => {
                  console.log(response.data.result.records);
                  this.tableData = response.data.result.records;
                });
            })
            .catch(() => {
              this.dialogVisible = true;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.card {
  margin: 10px;
  background-color: white;
  width: 1700px;
  height: 600px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>