<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <!-- <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本校学生智慧卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
        >
        </el-switch>
      </div> -->
      <div class="details">
        设备管理<span
          style="margin-left: 20px; cursor: pointer; color: #5d9cff"
          @click="isData = false"
          v-if="isData"
          >查看数据表</span
        >
        <span
          style="margin-left: 20px; cursor: pointer; color: #5d9cff"
          @click="isData = true"
          v-if="!isData"
          >查看统计图</span
        >
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div class="item" @click="$router.push('/basicManage')">
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/list_white.png"
          />基础管理
        </div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div
        class="item"
        style="
          background: #f5f6fa;
          color: #5d9cff;
          border-bottom: 1px solid #b9d5fa;
        "
      >
        <img
          style="margin-right: 10px"
          src="../assets/devices_blue.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content" v-show="isData">
      <div class="card" style="height: 80px">
        <div class="inner">
          <el-select
            v-model="value1"
            placeholder="请选择"
            style="width: 450px"
            @change="selectGradeSearch"
          >
            <el-option
              v-for="item in gradeList"
              :key="item.gradeName"
              :label="item.gradeName"
              :value="item.gradeId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="card" style="height: 290px; width: 840px">
        <div class="inner">
          <div style="font-size: 16px">设备申请通过情况</div>
          <div id="mainLeft1" style="width: 100%"></div>
        </div>
      </div>
      <div class="card" style="height: 290px; width: 840px">
        <div class="inner">
          <div style="font-size: 16px">最近24小时在线情况</div>
          <div id="mainLeft2" style="width: 100%"></div>
        </div>
      </div>
      <div class="card" style="height: 300px">
        <div class="inner">
          <div style="font-size: 16px">设备在线情况</div>
          <div id="mainLeft3" style="width: 100%"></div>
        </div>
      </div>
    </div>
    <div class="content" v-show="!isData">
      <div class="card">
        <div class="inner">
          <div
            style="
              font-size: 16px;
              margin-bottom: 35px;
              margin-top: 35px;
              position: relative;
            "
          >
            <span>设备卡号：</span>
            <el-input
              v-model="inputCardNo"
              placeholder="请输入"
              style="width: 230px"
              clearable
            ></el-input>
            <span style="margin-left: 80px">联系方式：</span>
            <el-input
              v-model="inputParentPhone"
              placeholder="请输入"
              style="width: 230px"
              clearable
            ></el-input>
            <span style="margin-left: 80px">学生姓名：</span>
            <el-input
              v-model="inputStudentName"
              placeholder="请输入"
              style="width: 230px"
              clearable
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              style="position: absolute; right: 20px; width: 100px"
              @click="search"
              >搜索</el-button
            >
          </div>
          <div style="font-size: 16px; margin-bottom: 35px; position: relative">
            数据列表
            <!-- <el-button
              type="warning"
              style="position: absolute; right: 130px; width: 100px"
              >导入</el-button
            > -->
            <el-button
              type="success"
              style="position: absolute; right: 20px; width: 100px"
              @click="exportExcel('表单')"
              >导出</el-button
            >
            <!-- <el-button
              type="primary"
              style="position: absolute; right: 20px; width: 100px"
              @click="dialogFormVisible=true"
              >新增</el-button
            > -->
          </div>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
            max-height="420px"
            ref="table"
          >
            <el-table-column
              type="index"
              label="序号"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="deviceId"
              label="设备ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="cardNo"
              label="设备卡号"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="parentPhone"
              label="联系方式"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="studentName"
              label="关联学生"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="gradeName"
              label="年级"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="className"
              label="班级"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="deviceStatus"
              label="使用状态"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.deviceStatus == "1" ? "使用中" : "未使用"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="online" label="在线状态" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.online == "1" ? "在线" : "离线" }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="method" label="操作" align="center">
              <template slot-scope="scope">
                <el-dialog
                  title="请确认"
                  :visible.sync="dialogVisibleCheck"
                  width="30%"
                >
                  <span>1.设备完好</span><br />
                  <span>2.设备号正确</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleCheck = false"
                      >取 消</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        dialogVisibleCheck = false;
                        logOut(logOutIndex, tableData);
                      "
                      >确 定</el-button
                    >
                  </span>
                </el-dialog>
                <el-button
                  v-if="scope.row.deviceStatus == 4"
                  @click.native.prevent="
                    dialogVisibleCheck = true;
                    logOutIndex = scope.$index;
                  "
                  type="text"
                  size="small"
                >
                  注销
                </el-button>

                <el-button
                  v-else-if="scope.row.deviceStatus == 3"
                  @click.native.prevent="recycle(scope.$index, tableData)"
                  type="text"
                  size="small"
                  style="color: red"
                >
                  回收
                </el-button>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            title="新增设备"
            :visible.sync="dialogFormVisible"
            :width="'30%'"
          >
            <el-form :model="form" :rules="rules" ref="form">
              <el-form-item
                label="校区编号"
                :label-width="formLabelWidth"
                prop="schoolId"
              >
                <el-input v-model="form.schoolId" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item
                label="设备号"
                :label-width="formLabelWidth"
                prop="deviceId"
              >
                <el-input v-model="form.deviceId" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="validateForm">确 定</el-button>
            </div>
          </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
          >
            <span>{{ errMsg }}</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  name: "studentManage",
  components: {},
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      mapType: "class",
      allow: false,
      input1: "",
      input2: "",
      input3: "",
      dialogVisibleCheck: false,
      timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      schoolApplyNums: 0,
      schoolDeviceNums: 0,
      schoolApprovalNums: 0,
      logOutIndex: undefined,
      onlineNums: 0,
      offlineNums: 0,
      deviceSosNums: 0,
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      isData: true,
      dialogVisible: false,
      formLabelWidth: "100px",
      dialogFormVisible: false,
      form: {
        deviceId: "",
        schoolId: "",
      },
      rules: {
        deviceId: [
          { required: true, message: "请输入设备号", trigger: "blur" },
        ],
        schoolId: [
          { required: true, message: "请输入校区编号", trigger: "blur" },
        ],
      },
      tableData: [
        // {
        //   id: 0,
        //   deviceId: "10013",
        //   deviceNo: "14700002145",
        //   class: "三班",
        //   grade: "初一",
        //   studentId: 1030,
        //   phone: 13300538438,
        //   studentName: "蔡丽洲",
        //   deviceStatus: "正常",
        //   online: "在线",
        // },
        // {
        //   id: 1,
        //   deviceId: "10007",
        //   deviceNo: "14700002355",
        //   class: "四班",
        //   grade: "初二",
        //   studentId: 1030,
        //   phone: 13900540838,
        //   studentName: "陈培然",
        //   deviceStatus: "正常",
        //   online: "在线",
        // },
        // {
        //   id: 2,
        //   deviceId: "10011",
        //   deviceNo: "14700002342",
        //   class: "一班",
        //   grade: "初一",
        //   studentId: 1030,
        //   phone: 13700023818,
        //   studentName: "徐依晨",
        //   deviceStatus: "正常",
        //   online: "在线",
        // },
      ],
      options: [
        {
          value: "选项1",
          label: "今天",
        },
      ],
      options1: [
        {
          value: "选项1",
          label: "一班",
        },
        {
          value: "选项2",
          label: "二班",
        },
        {
          value: "选项3",
          label: "三班",
        },
        {
          value: "选项4",
          label: "四班",
        },
        {
          value: "选项5",
          label: "五班",
        },
      ],
      value: "",
      value1: "全部",
      parentInfo: [],
      deviceInfo: [],
      gradeList: [],
      inputCardNo: "",
      inputStudentName: "",
      inputParentPhone: "",
      errMsg: "系统错误",
    };
  },
  mounted() {
    // this.drawChart();
    // this.drawChart1();
    this.$http
      .get(
        `${this.$baseUrl}/class/group/findGrades?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.gradeList = response.data.result.records;
        this.gradeList.unshift({
          gradeName: "全部",
          gradeId: "all",
        });
      });
    this.$http
      .post(`${this.$baseUrl}/card/info/queryDeviceOnline`, {
        schoolId: this.schoolData.schoolId,
        interval: 60,
      })
      .then((response) => {
        console.log(response.data.result);
        let temp = [];
        for (let i = 0; i < 12; i++) {
          temp.unshift(response.data.result["" + i]);
          this.onlineData = temp;
        }
        this.drawChartLeft3();
        console.log(temp);
      });
    // this.drawChart2();
    this.$http
      .post(`${this.$baseUrl}/card/info/queryDeviceOnlineCurrent`, {
        schoolId: this.schoolData.schoolId,
      })
      .then((response) => {
        console.log(response.data.result);
        this.onlineNums = response.data.result.online_nums;
        this.offlineNums = response.data.result.offline_nums;
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceByTimes`, {
            schoolId: this.schoolData.schoolId,
          })
          .then((response) => {
            console.log(response.data.result);
            this.deviceSosNums = response.data.result.Device_sosNums;
            this.drawChartLeft2();
          });
      });
    this.$http
      .post(`${this.$baseUrl}/card/info/queryDeviceApply`, {
        schoolId: this.schoolData.schoolId,
      })
      .then((response) => {
        console.log(response.data.result);
        this.schoolApplyNums = response.data.result.School_Apply_Nums;
        this.schoolDeviceNums = response.data.result.School_Device_Nums;
        this.schoolApprovalNums = response.data.result.School_Approval_Nums;
        this.drawChartLeft1();
      });
    this.$http
      .get(
        `${this.$baseUrl}/StudentsDevices/findStudentsDevicesByCondition?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.tableData = response.data.result.records;
      });
  },
  methods: {
    selectGradeSearch(event) {
      if (event == "all") {
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceOnline`, {
            schoolId: this.schoolData.schoolId,
            interval: 60,
          })
          .then((response) => {
            console.log(response.data.result);
            let temp = [];
            for (let i = 0; i < 12; i++) {
              temp.unshift(response.data.result["" + i]);
              this.onlineData = temp;
            }
            this.drawChartLeft3();
            console.log(temp);
          });
        // this.drawChart2();
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceOnlineCurrent`, {
            schoolId: this.schoolData.schoolId,
          })
          .then((response) => {
            console.log(response.data.result);
            this.onlineNums = response.data.result.online_nums;
            this.offlineNums = response.data.result.offline_nums;
            this.$http
              .post(`${this.$baseUrl}/card/info/queryDeviceByTimes`, {
                schoolId: this.schoolData.schoolId,
              })
              .then((response) => {
                console.log(response.data.result);
                this.deviceSosNums = response.data.result.Device_sosNums;
                this.drawChartLeft2();
              });
          });
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceApply`, {
            schoolId: this.schoolData.schoolId,
          })
          .then((response) => {
            console.log(response.data.result);
            this.schoolApplyNums = response.data.result.School_Apply_Nums;
            this.schoolDeviceNums = response.data.result.School_Device_Nums;
            this.schoolApprovalNums = response.data.result.School_Approval_Nums;
            this.drawChartLeft1();
          });
      } else {
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceOnline`, {
            schoolId: this.schoolData.schoolId,
            gradeId: event,
            interval: 60,
          })
          .then((response) => {
            console.log(response.data.result);
            let temp = [];
            for (let i = 0; i < 12; i++) {
              temp.unshift(response.data.result["" + i]);
              this.onlineData = temp;
            }
            this.drawChartLeft3();
            console.log(temp);
          });
        // this.drawChart2();
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceOnlineCurrent`, {
            schoolId: this.schoolData.schoolId,
            gradeId: event,
          })
          .then((response) => {
            console.log(response.data.result);
            this.onlineNums = response.data.result.online_nums;
            this.offlineNums = response.data.result.offline_nums;
            this.$http
              .post(`${this.$baseUrl}/card/info/queryDeviceByTimes`, {
                schoolId: this.schoolData.schoolId,
              })
              .then((response) => {
                console.log(response.data.result);
                this.deviceSosNums = response.data.result.Device_sosNums;
                this.drawChartLeft2();
              });
          });
        this.$http
          .post(`${this.$baseUrl}/card/info/queryDeviceApply`, {
            schoolId: this.schoolData.schoolId,
            gradeId: event,
          })
          .then((response) => {
            console.log(response.data.result);
            this.schoolApplyNums = response.data.result.School_Apply_Nums;
            this.schoolDeviceNums = response.data.result.School_Device_Nums;
            this.schoolApprovalNums = response.data.result.School_Approval_Nums;
            this.drawChartLeft1();
          });
      }
      console.log(event);
    },
    log(index, data) {
      console.log(index, data);
    },
    logOut(index, data) {
      console.log(index, data);
      this.$http
        .post(
          `${this.$baseUrl}/card/info/logout?deviceId=${data[index].deviceId}&damaged=false&operator=${this.schoolData.schoolManager}`
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.errMsg = "操作失败";
            this.dialogVisible = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/StudentsDevices/findStudentsDevicesByCondition?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
            )
            .then((response) => {
              console.log(response.data.result.records);
              this.tableData = response.data.result.records;
            });
        });
    },
    recycle(index, data) {
      console.log(index, data);
      this.$http
        .post(
          `${this.$baseUrl}/card/info/recycle?deviceId=${data[index].deviceId}&damaged=false&operator=${this.schoolData.schoolManager}`
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.errMsg = "操作失败";
            this.dialogVisible = true;
            return;
          }
          this.$http
            .post(`${this.$baseUrl}/api/wxpay/refund`, {
              orderId: res.data.result.split(",")[1],
              isOldCard: "0",
              deviceId: res.data.result.split(",")[0],
            })
            .then((res) => {
              if (res.data.code != 200) {
                this.errMsg = "操作失败";
                this.dialogVisible = true;
                return;
              }
              this.$http
                .get(
                  `${this.$baseUrl}/students/info/unbindCard?cardNo=${data[index].cardNo}&studentId=${data[index].studentId}`
                )
                .then((res) => {
                  if (res.data.code != 200) {
                    this.errMsg = "操作失败";
                    this.dialogVisible = true;
                    return;
                  }
                  this.$http
                    .get(
                      `${this.$baseUrl}/StudentsDevices/findStudentsDevicesByCondition?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
                    )
                    .then((response) => {
                      console.log(response.data.result.records);
                      this.tableData = response.data.result.records;
                    });
                });
            });
        });
    },
    search() {
      if (
        this.inputCardNo.length < 1 &&
        this.inputStudentName.length < 1 &&
        this.inputParentPhone.length < 1
      ) {
        this.searched = false;
        this.$http
          .get(
            `${this.$baseUrl}/StudentsDevices/findStudentsDevicesByCondition?page=1&size=1000&schoolId=${this.schoolData.schoolId}`
          )
          .then((response) => {
            console.log(response.data.result.records);
            this.tableData = response.data.result.records;
          });
        return;
      }
      this.$http
        .get(
          `${
            this.$baseUrl
          }/StudentsDevices/findStudentsDevicesByCondition?page=1&size=100${
            this.inputCardNo.length > 0 ? "&cardNo=" + this.inputCardNo : ""
          }${
            this.inputStudentName.length > 0
              ? "&studentName=" + this.inputStudentName
              : ""
          }${
            this.inputParentPhone.length > 0
              ? "&parentPhone=" + this.inputParentPhone
              : ""
          }&schoolId=${this.schoolData.schoolId}`
        )
        .then((response) => {
          console.log(response.data.result.records);
          this.searched = true;
          this.tableData = response.data.result.records;
        });
    },
    getStudentId(id) {
      this.$http
        .get(`${this.$baseUrl}/StudentsDevices/findByDeviceId/${id}`)
        .then((response) => {
          console.log(response.data.result.records);
          this.deviceInfo.push(response.data.result[0]);
        });
    },
    getPhone(id) {
      this.$http
        .get(`${this.$baseUrl}/parentsStudents/findByStudentId?studentId=${id}`)
        .then((response) => {
          console.log(response.data.result.records);
          this.parentInfo.push(response.data.result[0]);
        });
    },
    exportExcel(excelName = "表单") {
      //excelName --设置导出的excel名称
      //report-table --对应的要导出的el-table的ref名称
      try {
        const $e = this.$refs["table"].$el;
        // 如果表格加了fixed属性，则导出的文件会生产两份一样的数据，所以可在这里判断一下
        let $table = $e.querySelector(".el-table__fixed");
        if (!$table) {
          $table = $e;
        }
        // 为了返回单元格原始字符串，设置{ raw: true }
        const wb = XLSX.utils.table_to_book($table, { raw: true });
        const wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          `${excelName}.xlsx`
        );
      } catch (e) {
        if (typeof console !== "undefined") console.error(e);
      }
    },
    validateForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$http
            .post(`${this.$baseUrl}/card/info/add`, {
              deviceId: this.form.deviceId,
              schoolId: this.form.schoolId,
            })
            .then((response) => {
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              console.log(response);
              if (response.data.code != 200) {
                this.errMsg = "提交信息有误";
                this.dialogVisible = true;
              }
            })
            .catch(() => {
              this.errMsg = "网络错误";
              this.dialogVisible = true;
            });
        } else {
          return false;
        }
      });
    },
    drawChartLeft1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft1"));
      let myContainer = document.getElementById("mainLeft1");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      const data = [
        { value: this.schoolDeviceNums, name: "全校设备数" },
        { value: this.schoolApplyNums, name: "设备申请数" },
        { value: this.schoolApprovalNums, name: "申请通过数" },
      ];
      let option = {
        color: ["#D95F7F", "#03B307", "#5D9CFF"],
        legend: {
          data: ["全校设备数", "设备申请数", "申请通过数"],
          orient: "vertical",
          x: "80%",
          y: "65%",
          textStyle: {
            //图例字体大小
            fontSize: 12,
            color: "#666666",
          },
          itemHeight: 10,
          itemWidth: 10,
          type: "scroll",
          formatter: function (name) {
            //通过name获取到数组对象中的单个对象
            let singleData = data.filter(function (item) {
              return item.name == name;
            });
            return name + " : " + singleData[0].value;
          },
        },
        xAxis: {
          type: "category",
          data: ["全校设备数", "设备申请数", "申请通过数"],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
            minInterval: 1,
          },
        ],
        grid: {
          left: "3%",
          right: "30%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            name: "全校设备数",
            barWidth: 20,
            stack: "total",
            data: [
              {
                value: this.schoolDeviceNums,
                itemStyle: {
                  color: "#D95F7F",
                },
              },
              0,
              0,
            ],
            type: "bar",
          },
          {
            name: "设备申请数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              {
                name: "设备申请数",
                value: this.schoolApplyNums,
                itemStyle: {
                  color: "#03B307",
                },
              },
              0,
            ],
            type: "bar",
          },
          {
            name: "申请通过数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              0,
              {
                value: this.schoolApprovalNums,
                itemStyle: {
                  color: "#5D9CFF",
                },
              },
            ],
            type: "bar",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChartLeft2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft2"));
      let myContainer = document.getElementById("mainLeft2");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      const data = [
        { value: this.onlineNums, name: "在线设备数" },
        { value: this.offlineNums, name: "离线设备数" },
        { value: this.deviceSosNums, name: "救援设备数" },
      ];
      let option = {
        color: ["#5D9CFF", "#BD0104", "#FFB964"],
        legend: {
          data: ["在线设备数", "离线设备数", "救援设备数"],
          orient: "vertical",
          x: "80%",
          y: "65%",
          textStyle: {
            //图例字体大小
            fontSize: 12,
            color: "#666666",
          },
          itemHeight: 10,
          itemWidth: 10,
          type: "scroll",
          formatter: function (name) {
            //通过name获取到数组对象中的单个对象
            let singleData = data.filter(function (item) {
              return item.name == name;
            });
            return name + " : " + singleData[0].value;
          },
        },
        xAxis: {
          type: "category",
          data: ["在线设备数", "离线设备数", "救援设备数"],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
            minInterval: 1,
          },
        ],
        grid: {
          left: "3%",
          right: "30%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            name: "在线设备数",
            barWidth: 20,
            stack: "total",
            data: [
              {
                value: this.onlineNums,
                itemStyle: {
                  color: "#5D9CFF",
                },
              },
              0,
              0,
            ],
            type: "bar",
          },
          {
            name: "离线设备数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              {
                value: this.offlineNums,
                itemStyle: {
                  color: "#BD0104",
                },
              },
              0,
            ],
            type: "bar",
          },
          {
            name: "救援设备数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              0,
              {
                value: this.deviceSosNums,
                itemStyle: {
                  color: "#FFB964",
                },
              },
            ],
            type: "bar",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChartLeft3() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft3"));
      let myContainer = document.getElementById("mainLeft3");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      let option = {
        xAxis: {
          type: "category",
          data: [
            (new Date(new Date().getTime() - 3600000 * 11).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 11).getHours()
              : new Date(new Date().getTime() - 3600000 * 11).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 10).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 10).getHours()
              : new Date(new Date().getTime() - 3600000 * 10).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 9).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 9).getHours()
              : new Date(new Date().getTime() - 3600000 * 9).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 8).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 8).getHours()
              : new Date(new Date().getTime() - 3600000 * 8).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 7).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 7).getHours()
              : new Date(new Date().getTime() - 3600000 * 7).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 6).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 6).getHours()
              : new Date(new Date().getTime() - 3600000 * 6).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 5).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 5).getHours()
              : new Date(new Date().getTime() - 3600000 * 5).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 4).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 4).getHours()
              : new Date(new Date().getTime() - 3600000 * 4).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 3).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 3).getHours()
              : new Date(new Date().getTime() - 3600000 * 3).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 2).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 2).getHours()
              : new Date(new Date().getTime() - 3600000 * 2).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 1).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 1).getHours()
              : new Date(new Date().getTime() - 3600000 * 1).getHours()) +
              ":00",
            (new Date(new Date().getTime() - 3600000 * 0).getHours() < 10
              ? "0" + new Date(new Date().getTime() - 3600000 * 0).getHours()
              : new Date(new Date().getTime() - 3600000 * 0).getHours()) +
              ":00",
          ],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
            minInterval: 1,
          },
        ],
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            data: this.onlineData,
            type: "line",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.card {
  margin: 10px;
  background-color: white;
  width: 1700px;
  height: 600px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>